<template>
  <el-dialog :title="title" width="1000" :visible.sync="dialogVisible">
    <el-table :data="list" border fit>
      <el-table-column label="退款申请时间" prop="createTime" />
      <el-table-column v-if="!isMealCardOrder" label="退款金额" prop="amountYuan" />
      <el-table-column v-else label="退款次数">
        <div>1次</div>
      </el-table-column>
      <el-table-column label="退款人" prop="operateAccountName" />
      <el-table-column label="退款来源" prop="sourceTypeLabel" />
      <el-table-column label="退款原因" prop="description" />
      <el-table-column label="退款状态" prop="statusLabel" />
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: "RefundRecordModal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    isMealCardOrder: Boolean,
  },

  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set() {
        this.onClickCancel();
      },
    },

    title() {
      return this.isMealCardOrder ? "套餐卡退款记录" : "退款记录";
    },
  },

  methods: {
    onClickCancel() {
      this.$emit("update:visible", false);
    },
  },
};
</script>
