import { orderRefundTransform } from "@charge/common";

import request from "@/plugins/axios";

class OrderOrderQueryApi {
  getOrderPowerURL = "/api/order/platform/orderQuery/getOrderPower";
  getOrderPowerEffectKey = `get${this.getOrderPowerURL}`;
  getOrderPower = async (params) => {
    const result = await request({
      url: this.getOrderPowerURL,
      params: params,
    });
    return orderRefundTransform.enrichList(result);
  };
}

export const orderOrderQueryApi = new OrderOrderQueryApi();
