<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="main orderdetail">
      <div class="orderdetail-top a-flex-rfsc">
        <span class="a-fs-16 a-fw-700">订单：{{ detail.orderId }}</span>
        <el-tag effect="plain" type="info" class="a-ml-16">
          <span
            class="roundspan"
            :class="{ grey: detail.status == 3, red: detail.status == 4 }"
          ></span>
          {{ detail.status ? detail.statusText : isPay ? "待充电" : "待支付" }}
        </el-tag>
      </div>
      <div :class="'orderdetail-step'">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step
            title="订单创建"
            :description="detail.createTimeText"
            icon="el-icon-my-yk_yuanquan_fill"
          ></el-step>
          <el-step
            title="支付成功"
            :description="detail.payTimeText"
            icon="el-icon-my-yk_yuanquan_fill"
          ></el-step>
          <el-step
            title="开始充电"
            :description="detail.startTimeText && isPay && isStart ? detail.startTimeText : ''"
            icon="el-icon-my-yk_yuanquan_fill"
          ></el-step>
          <el-step
            title="充电结束"
            :description="detail.endTimeText && isPay && isEnd ? detail.endTimeText : ''"
            icon="el-icon-my-yk_yuanquan_fill"
          ></el-step>
        </el-steps>
      </div>
      <div class="orderdetail-box">
        <div class="a-fs-16 a-fw-700">订单信息</div>
        <div class="orderdetail-box-orderinfo">
          <div class="a-flex-rfsc title">
            <span class="dot"></span>
            <span class="a-fs-14 a-ml-08">用户信息</span>
          </div>
          <div class="content a-flex-rfsc a-flex-wrap a-w-100">
            <div>
              <span>用户名</span>
              <le-jumpto-detail
                v-if="isYADEA"
                url="/userMGT/userMGT-detail"
                :d-query="{ id: detail.userId }"
              >
                <span class="a-c-blue">{{ detail.userName }}</span>
              </le-jumpto-detail>
              <span v-else class="a-c-blue">{{ detail.userName }}</span>
            </div>
            <div>
              <span>手机号</span>
              <span>{{ detail.mobile }}</span>
            </div>
          </div>
          <div class="a-flex-rfsc title">
            <span class="dot"></span>
            <span class="a-fs-14 a-ml-08">设备信息</span>
          </div>
          <div class="content a-flex-rsbc a-flex-wrap a-w-100">
            <div>
              <span>设备编号</span>
              <le-jumpto-detail
                url="/device/device-info"
                :d-query="{ deviceCode: detail.deviceCode }"
              >
                <span class="a-c-blue">{{ detail.deviceCode }}</span>
              </le-jumpto-detail>
            </div>
            <div>
              <span>插座号</span>
              <span>{{ detail.slot }}</span>
            </div>
            <div>
              <span>设备地址</span>
              <le-jumpto-detail
                url="/station/station-detail"
                :d-query="{ stationId: detail.stationId }"
              >
                <span class="a-c-blue">{{ detail.address }}</span>
              </le-jumpto-detail>
            </div>
          </div>
          <div class="a-flex-rfsc title">
            <span class="dot"></span>
            <span class="a-fs-14 a-ml-08">充电信息</span>
          </div>
          <div class="content a-flex-rsbc a-flex-wrap a-w-100">
            <div>
              <span>下单时间</span>
              <span>{{ detail.createTimeText }}</span>
            </div>
            <div>
              <span>开始时间</span>
              <span>{{ detail.startTimeText }}</span>
            </div>
            <div>
              <span>结束时间</span>
              <span>{{ detail.endTimeText }}</span>
            </div>
            <div>
              <span>选择充电时间</span>
              <span>{{ detail.planMinutes }} 分钟</span>
              <el-link class="a-ml-10" type="primary" @click="onClickFeeRule">计费规则</el-link>
            </div>
            <div>
              <span>实际充电时间</span>
              <span>{{ detail.realMinutes }} 分钟</span>
            </div>
            <div>
              <span>订单状态</span>
              <div>
                <span
                  class="roundspan"
                  :class="{ grey: detail.status == 3, red: detail.status == 4 }"
                ></span>
                <span>
                  {{ detail.status ? detail.statusText : isPay ? "待充电" : "待支付" }}
                </span>
              </div>
              <el-link class="cg-ml-12" type="primary" @click="onClickRefundRecord">
                退款记录
              </el-link>
            </div>
            <div>
              <span>结束原因</span>
              <span>{{ detail.endReason }}</span>
            </div>
            <div>
              <span>设备上报结束原因：</span>
              <span>{{ detail.deviceReportStopReason }}</span>
            </div>
            <div>
              <span>安心充电</span>
              <span>{{ insureStatusOptionsMap[detail.secureServiceStatus] }}</span>
            </div>
          </div>
          <div class="a-flex-rfsc title">
            <span class="dot"></span>
            <span class="a-fs-14 a-ml-08">支付信息</span>
          </div>
          <div class="content a-flex-rfsc a-flex-wrap a-w-100">
            <div>
              <span>支付方式</span>
              <span>{{ detail.payTypeText }}</span>
            </div>
            <div>
              <span>订单来源</span>
              <span>{{ detail.orderSourceTypeText }}</span>
            </div>
            <div>
              <span>消费金额</span>
              <span>&yen;{{ util.numFormat(detail.amount) }}</span>
            </div>
            <div>
              <span>预充金额</span>
              <span>&yen;{{ util.numFormat(detail.orderAmount) }}</span>
            </div>
            <div>
              <span>退款金额</span>
              <span>&yen; {{ util.numFormat(detail.refundAmount) }}</span>
            </div>
            <div v-if="detail.isMealCardOrder">
              <span>套餐卡是否已退</span>
              <span class="cg-pr-12">{{ detail.mealCardRefundStatusLabel }}</span>
              <el-link
                v-if="detail.mealCardRefundStatusIsRefunded"
                type="primary"
                @click="onClickMealCardRefundRecord"
              >
                套餐卡退款记录
              </el-link>
            </div>
          </div>
        </div>
      </div>
      <div class="orderdetail-box">
        <div class="a-fs-16 a-fw-700" style="margin-bottom: 12px">功率记录</div>
        <el-radio-group v-model="showPowerType">
          <el-radio-button label="charts">图表</el-radio-button>
          <el-radio-button label="list">列表</el-radio-button>
        </el-radio-group>
        <div v-if="showPowerType === 'list'" class="table">
          <le-pagview id="table_box" :page-param="pageParam" @setData="setTableData">
            <el-table
              v-sticky="{ parent: '.el-card__body' }"
              :data="tableData"
              :highlight-current-row="true"
              style="width: 100%"
            >
              <el-table-column prop="powerTimeText" label="时间" />
              <el-table-column prop="rmsCurrent" label="电流" />
              <el-table-column prop="activePower" label="有功功率" />
              <el-table-column prop="apparentPower" label="视在功率" />
            </el-table>
          </le-pagview>
        </div>
        <v-chart v-else style="height: 400px" :option="option" autoresize></v-chart>
      </div>
      <div v-if="detail.canRefund || detail.status === 1" class="a-line-t-e0 footerBox">
        <el-button
          v-if="detail.canRefund"
          class="a-ml-24 a-mt-15"
          style="margin-left: 24px"
          type="danger"
          plain
          @click="refund"
        >
          &nbsp;&nbsp;&nbsp;退款&nbsp;&nbsp;&nbsp;
        </el-button>
        <el-button
          v-if="detail.status === 1"
          class="a-ml-24 a-mt-15"
          type="danger"
          plain
          @click="onClickEndOrder"
        >
          结束充电
        </el-button>
      </div>
    </el-card>

    <fee-rule-modal
      :visible.sync="showFeeRuleModal"
      :order-id="detail.orderId"
      :max-power="detail.maxPower"
      :show-order-settings="false"
    />

    <refund-modal
      :visible.sync="showRefundModal"
      :order-info="detail"
      @success="getOrderInfoById"
    />

    <refund-record-modal :visible.sync="showRefundRecordModal" :list="refundRecord" />

    <refund-record-modal
      :visible.sync="showMealCardRefundRecordModal"
      :list="refundRecord"
      is-meal-card-order
    />
  </div>
</template>

<script>
import { orderQueryTransform, orderTransform } from "@charge/common";
import dayjs from "dayjs";
import { Message } from "element-ui";
import VChart from "vue-echarts";
import { mapState } from "vuex";

import FeeRuleModal from "@/components-new/FeeRuleModal/index.vue";
import { orderOrderQueryApi } from "@/services/apis/order/order-query";
import { orderRefundApi } from "@/services/apis/order/order-refund";

import util from "../../utils/util";
import RefundModal from "./components/RefundModal.vue";
import RefundRecordModal from "./components/RefundRecordModal.vue";
import { INSURE_STATUS } from "./status";

const YEDEA_ID = 2;

export default {
  name: "OrderDetail",

  components: {
    FeeRuleModal: FeeRuleModal,
    RefundModal: RefundModal,
    RefundRecordModal: RefundRecordModal,
    VChart: VChart,
  },

  data() {
    return {
      orderId: 0,
      showFeeRuleModal: false,
      showRefundModal: false,
      showRefundRecordModal: false,
      refundRecord: [],
      showMealCardRefundRecordModal: false,
      util: util,
      active: 0,
      reverse: true,
      detail: {},
      powerList: null,
      showPowerType: "charts",

      tableData: [],
      pageParam: {
        url: orderOrderQueryApi.getOrderPowerURL,
        method: "get",
        params: {
          orderId: parseInt(this.$route.query.orderId, 10),
        },
        freshCtrl: 1,
      },
    };
  },
  computed: {
    ...mapState("company", ["company"]),
    insureStatusOptionsMap() {
      return INSURE_STATUS.reduce((map, item) => {
        map[item.value] = item.label;
        return map;
      }, {});
    },
    option() {
      return {
        legend: {
          itemWidth: 20,
          itemHeight: 8,
          top: "3%",
          right: "4%",
          itemGap: 40,
          data: [
            {
              name: "有功功率",
              icon: "roundRect",
            },
          ],
        },
        grid: {
          left: "60px",
          right: "60px",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.powerList?.map((v) => dayjs(v.powerTimeText).format("MM-DD HH:mm")) || [],
          axisLine: {
            symbol: ["none", "path://M5,20 L5,5 L8,8 L5,2 L2,8 L5,5 L5.3,6 L5.3,20"],
            symbolSize: [20, 35],
            symbolOffset: 20,
            lineStyle: {
              shadowOffsetX: 35,
              shadowColor: "#666",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "W",
          nameGap: 20,
          nameTextStyle: {
            padding: [0, 0, 0, -40],
          },
          axisLine: {
            show: true,
            symbol: ["none", "path://M5,20 L5,5 L8,8 L5,2 L2,8 L5,5 L5.3,6 L5.3,20"],
            symbolSize: [20, 35],
            symbolOffset: 20,
            lineStyle: {
              shadowOffsetY: -35,
              shadowColor: "#666",
            },
          },
          boundaryGap: false,
        },
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            const data = params[0];
            return `${this.powerList?.[data.dataIndex].powerTimeText}<br/>有功功率：${data.data}W`;
          },
        },
        series: [
          {
            name: "有功功率",
            data: this.powerList?.map((v) => v.activePower),
            type: "line",
            showSymbol: false,
          },
        ],
      };
    },
    // 是否支付
    isPay() {
      return [1, 2, 3].includes(this.detail.payStatus);
    },
    // 是否开始
    isStart() {
      return [1, 2, 3, 6].includes(this.detail.status);
    },
    // 是否结束
    isEnd() {
      return [2, 3, 6].includes(this.detail.status);
    },
    isYADEA() {
      return this.company.id === YEDEA_ID;
    },
  },

  activated() {
    const orderId = parseInt(this.$route.query.orderId, 10);
    if (orderId && orderId !== this.orderId) {
      this.orderId = orderId;
      this.getOrderInfoById();
      this.getOrderPower();
      this.getRefundRecord();
    }
  },

  methods: {
    async getRefundRecord() {
      this.refundRecord = await orderRefundApi.list({ orderId: this.orderId });
    },
    onClickFeeRule() {
      if (this.detail.payType === 6) {
        Message({
          message: "无计费信息，该订单为套餐卡支付",
          type: "error",
          duration: 3 * 1000,
        });
        return;
      }
      this.showFeeRuleModal = true;
    },
    //获取订单详情
    getOrderInfoById() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getOrderInfoById,
          params: {
            orderId: this.orderId,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.getOrderPower();
            this.detail = orderTransform.enrichOrder(res.result.data);
            if (this.detail.createTimeText) {
              this.active = 0;
            }
            if (this.detail.payTimeText && this.isPay) {
              this.active = 1;
            }
            if (this.detail.startTimeText && this.isPay && this.isStart) {
              this.active = 2;
            }
            if (this.detail.endTimeText && this.isPay && this.isEnd) {
              this.active = 3;
            }
          }
        });
    },
    //获取功率记录
    getOrderPower() {
      if (this.detail.payType !== 6) {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getOrderPower,
            params: {
              orderId: this.orderId,
            },
          })
          .then((res) => {
            if (res.result.code == 0) {
              this.powerList = res.result.data;
            }
          });
      }
    },
    //退款
    refund() {
      this.showRefundModal = true;
    },
    onClickEndOrder() {
      this.$confirm("请确认是否结束充电?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.cancelByAdministrator,
            params: {
              orderId: this.orderId,
            },
          })
          .then(() => {
            this.getOrderInfoById();
            this.$message({
              type: "success",
              message: "结束充电成功",
            });
          });
      });
    },

    onClickRefundRecord() {
      this.showRefundRecordModal = true;
    },

    onClickMealCardRefundRecord() {
      this.showMealCardRefundRecordModal = true;
    },

    setTableData(data) {
      this.tableData = orderQueryTransform.enrichList(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  overflow: auto;
}

.roundspan {
  height: 8px;
  width: 8px;
  background: #007aff;
  border-radius: 100%;
  display: inline-block;
  margin-right: 6px;

  &.grey {
    background: #c0c4cc;
  }

  &.red {
    background: #ff3b30;
  }
}

.orderdetail {
  padding-bottom: 100px;

  &-top {
    border-bottom: 1px solid #ebf0f5;
    padding: 24px 24px 16px;
  }

  &-step {
    padding: 35px 0 0;
  }

  &-box {
    font-size: 14px;
    line-height: 22px;
    padding: 56px 24px 0;

    &-orderinfo {
      .title {
        padding-top: 16px;

        span {
          font-weight: bold;

          &.dot {
            width: 6px;
            height: 6px;
            background: #797979;
          }
        }
      }

      .content {
        padding-left: 14px;
        padding-bottom: 8px;

        > div {
          width: 33.3%;
          padding-top: 16px;
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-content: flex-start;

          > span {
            &:nth-of-type(1) {
              color: #666666;
              margin-right: 16px;
              // font-family: PingFangSC-Regular, PingFang SC;
            }

            &:nth-of-type(2) {
              color: #333;

              font-family: PingFangSC-Regular, PingFang SC;
            }
          }
        }
      }
    }

    .table {
      padding-top: 16px;

      > div {
        font-size: 14px;
        line-height: 22px;
        padding: 14px 0;
        padding-left: 8px;

        > span {
          width: 25%;
          color: #303133;
        }

        border-bottom: 1px solid #ebf0f5;
      }

      &-header {
        background: #f5f7fa;

        border: none;

        > span {
          color: #606366;
          font-weight: bold;
        }
      }
    }
  }
}

/deep/ .el-card__body {
  padding: 0;
}

.order {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: #007aff;
  display: inline-block;
}

.el-icon-my-yk_yuanquan_fill {
  font-size: 20px;
}

/deep/ .is-process .el-icon-my-yk_yuanquan_fill {
  color: #007aff;
}

/deep/ .is-success .el-step__icon-inner {
  background: #007aff;
  color: #fff;
  padding: 2px;
  border-radius: 100%;
}

/deep/ .el-step__head.is-success {
  color: #007aff;
  border-color: #007aff;

  .el-step__line {
    background-color: #007aff;
  }
}

/deep/ .el-step__head.is-process {
  color: #007aff;
  border-color: #007aff;
}

/deep/ .el-step__title {
  color: #303133;
  font-size: 14px;
}

/deep/ .el-step__description {
  color: #909499;
}

.orderdetail-step-cancle {
  padding: 35px 0 0;

  /deep/ .is-process .el-icon-my-yk_yuanquan_fill {
    color: #c0c4cc;
  }

  /deep/ .is-success .el-step__icon-inner {
    background: #c0c4cc;
    color: #fff;
    padding: 2px;
    border-radius: 100%;
  }

  /deep/ .el-step__head.is-success {
    color: #c0c4cc;
    border-color: #c0c4cc;

    .el-step__line {
      background-color: #c0c4cc;
    }
  }

  /deep/ .el-step__head.is-process {
    color: #c0c4cc;
    border-color: #c0c4cc;
  }
}

.footerBox {
  left: 190px;
  right: 43px;
  position: fixed;
  bottom: 0;
  background: #fff;
  padding-bottom: 24px;
}

._end-order {
  position: absolute;
  left: 56px;
  bottom: 40px;
  width: 100px;
}
</style>
